import React from 'react';
import imgLogo from '../assets/images/logo.png';
import imgClose from '../assets/images/close.png';
import imgSelect from '../assets/images/select_main.png';
import { videoArr } from '../data/info';

export default class StartComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {pageKey: props.pageKey, tSize:props.tSize};
	}

	componentDidMount() {
	}

	componentWillReceiveProps(nextProps) {
		['pageKey', 'tSize'].forEach(key => {
			if (this.state[key] !== nextProps[key]) {
				this.setState({[key]:nextProps[key]});
			}
		});
	}

	setScroll = (topPro) => {
		const scrollDiv = document.getElementById('modalTermsContent'), innerDiv = document.getElementById('samsung-legal'), innerHeight = innerDiv.offsetHeight;
		scrollDiv.scrollTop = Math.round(topPro/100 * innerHeight);
	}

	addUrl = (urlStr, linkStr) => {
		return <label className='url' onClick={()=> {
			window.open(linkStr || urlStr, '_blank')
		}}>{urlStr}</label>
	}

	render() {
		const {pageKey, modalShow, tSize, loading} = this.state;
		return (
			<div className={`back-board part1-select flex blue-back ${pageKey==='select'?'active':''}`} style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
				<div className="black-wrapper flex zoom" id="selectBlack">
					<div className="select-part flex">
						<img className="logo" src={imgLogo} alt=''></img>
					</div>
					<div className="select-part flex">
						<div className="select-item flex" onClick={() => {
							['soundOut', 'soundSME60', 'soundSME15'].forEach(id => {
								var audio = document.getElementById(id);
								if (id==='soundSME60' || id==='soundSME15') {
									audio.muted = true; audio.play();
									setTimeout(() => { audio.pause(); audio.currentTime = 0; audio.muted = false; }, 500);
								} else audio.play();
							});
							this.setState({loading:true});
							for (let i = 0; i < videoArr.length-1; i++) {
								setTimeout(() => {
									const videoEle = document.getElementById(videoArr[i].key);
									videoEle.muted = true;
									videoEle.play();
								}, i*100);
								setTimeout(() => {
									const videoEle = document.getElementById(videoArr[i].key);
									videoEle.pause();
									videoEle.currentTime = 0;
								}, i*100 + 5);
							}
							const lastVideo = document.getElementById(videoArr[8].key); lastVideo.load();
							setTimeout(() => {
								this.props.callCanvasPage();
								this.setState({loading:false});
							}, videoArr.length * 100 + 500);
						}}>
							<img src={imgSelect} alt=''></img>
							<div className='label'>Enter V.I.P. Club</div>
						</div>
					</div>
					<div className="terms-button" onClick={()=>{
						this.setState({modalShow:true});
						gtag("event", "modal_view", { app:'instore', modal_name: 'terms_and_conditions' });
					}}>CLICK HERE FOR Samsung Privacy Policy</div>
					<div className="terms-label flex">
						<div>Experience best viewed on Safari and Chrome Browsers</div>
						<div>Disable Auto Orientation Lock</div>
					</div>
				</div>

				<div className={`modal-back modal-terms ${modalShow?'active':''}`}>
					<div className="modal-wrapper">
						<div className="modal-title">Samsung Augmented Reality Experience US Privacy Notice</div>
						<div className="modal-content" id='modalTermsContent'>
							<div>Effective Date August 30th, 2022</div>

							<div>Samsung Electronics Co., Ltd., along with our subsidiaries and affiliates (collectively, "Samsung") respects your concerns about privacy. This Samsung Augmented Reality Experience Privacy Notice (“Privacy Notice”) describes the experience and applies to the information we collect through the Sams’s Club Augmented Reality Experience (hereinafter the "AR Experience"). It does not apply to information collected by other apps, websites or services provided by Samsung.</div>
							<div>This AR Experience is designed to provide an enjoyable adventure and allow you to interact with technology in order to create an augmented reality image with personalities from the AR Experience (“AR image”). If you like, you can store your AR image on your personal mobile device.</div>
							<div>This Privacy Notice supplements the Samsung Privacy Policy for the U.S. (available at {this.addUrl('https://account.samsung.com/membership/terms/privacypolicy')} ) (“Samsung US Privacy Policy”) and provides additional information about what we collect and store in the AR Experience. In the event that any portion of this Privacy Notice conflicts with the Samsung US Privacy Policy, the relevant provisions of this Privacy Notice will govern.</div>

							<div className='title'>Information We Obtain from You by Automated Means</div>
							<div>The AR Experience is assessable from a web-based platform. Once you scan the QR code, you will be able to enter an augmented reality platform and can interact with characters and objects. You will also be able to take an image of yourself that can be merged into an image of associated personalities (“AR Image”). Once your new AR image is created, a unique, hashed URL for that AR image will be provided to you. You can access this hashed URL and store the AR Image on your personal mobile device. The hashed URL that contains the AR image will only be available for a few minutes. Once the URL expires, the only copy of that AR image will be what you have on your mobile device.</div>
							<div className='list-title'>The information that we will obtain from you includes:</div>
							<div className='list-array'>
								<div className='list-item'><label> • </label> Inclusion of data collected by QR codes</div>
								<div className='list-item'><label> • </label> Number of total scans</div>
								<div className='list-item'><label> • </label> Location based data based on IP address</div>
								<div className='list-item'><label> • </label> Desktop vs. Mobile</div>
								<div className='list-item'><label> • </label> Top Countries, States and Cities</div>
								<div className='list-item'><label> • </label> Daypart scan breakdown</div>
								<div className='list-item'><label> • </label> Operating System (iOS vs. Android vs. Other)</div>
							</div>
							
							<div className='title'>Information You Will Need</div>
							<div className='list-array'>
								<div className='list-item'><label> • </label> Your image (or selfie) (to be stored only on your mobile device)</div>
							</div>

							<div className='title'>Information Restrictions</div>
							<div>Neither Samsung nor its service providers will not collect your name, email address or device ID. We will not form any inferences about you from your use of the AR Experience. Moreover, we will not use your AR Image for any advertising purposes. Since we will neither collect nor store any of your contact information, we will not send you any targeted advertising based on the AR Experience. There will be no copies of your AR Image on our servers.</div>

							<div className='title'>The sole use of the AR Image will be made by you the consumer.</div>

							<div className='title'>Information Sharing</div>
							<div>The AR Experience will be administered by Samsung’s service provider, Drury Designs ({this.addUrl('https://www.drurydesign.com/')}) and MARS Advertising, Inc. d/b/a The Mars Agency Group (https://www.themarsagency.com/). Sam’s Club shall also have access to the information connected to this AR Experience. For further information on how Sam’s Club processes consumer information please visit {this.addUrl('https://corporate.samsclub.com/sams-club-privacy-policy')}.</div>
							<div>Additionally we use third-party analytics products, such as Google Analytics. The information we obtain may be disclosed to or collected directly by these providers only to help administer the AR Experience. To learn more about Google Analytics, please visit {this.addUrl('https://www.google.com/policies/privacy/partners/')}.</div>

							<div className='title'>Notice To California Residents</div>
							<div>If you are a California consumer, for more information about your privacy rights, please see this section of the Samsung US Privacy Policy called “California Consumer Privacy Statement,” available at {this.addUrl('https://account.samsung.com/membership/terms/privacypolicy')} and this section of the Sam’s Club Privacy Policy {this.addUrl('https://corporate.samsclub.com/sams-club-privacy-policy#what-are-your-california-privacy-rights')}.</div>
							
							<div className='title'>Notice to Colorado and Virginia Residents</div>
							<div>If you are a Colorado or Virginia resident, for more information about your privacy rights, please see the section of the Samsung US Privacy Policy called “Your Rights and Choices,” available at {this.addUrl('https://account.samsung.com/membership/terms/privacypolicy')}.</div>
							
							<div className='title'>How to Contact Us</div>
							<div>If you have specific questions or information requests about privacy please send an email to Samsung ({this.addUrl('NAprivacy@sea.samsung.com', 'https://mail.google.com/mail')}) or to Sam’s Club ({this.addUrl('privacy@samsclub.com', 'https://mail.google.com/mail')}).</div>
						</div>
						<img className="close" src={imgClose} onClick={()=>this.setState({modalShow:false}) } alt=''></img>
					</div>
				</div>
				<div className={`loading ${loading?'active':''}`}>
					<div className='loading-circle grey'></div>
				</div>
			</div>
		);
	}
}
