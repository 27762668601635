import React from 'react';
import imgClose from '../assets/images/close.png';
import { tvModelArr, subModalLabel, benefit2Year, benefit3Year, benefit5Year, benefit6Pro } from '../data/info';
import imgStarWhite from '../assets/images/star-white.png';

const preShopUrl = 'https://www.samsclub.com/b/samsung-tvs-and-home-theater/11960109?rootDimension=Resolution%3A';

export default class ModalSubComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {modalSub: props.modalSub, modalSubIn:props.modalSubIn, tSize:props.tSize, subArr:[]};
	}

	componentDidMount() {
	}

	componentWillReceiveProps(nextProps) {
		['modalSub', 'modalSubIn', 'tSize'].forEach(key => {
			if (this.state[key] !== nextProps[key]) {
				this.setState({[key]:nextProps[key]}, () => {
					if (key==='modalSub' && this.state.modalSub) {
						this.setDetailInfo();
					}
				});
			}
		});
	}

	setDetailInfo = () => {
		const {modalSub} = this.state;
		this.selMainInfo = tvModelArr.find(mainItem=>{return mainItem.key===modalSub});
		const {subArr, label} = this.selMainInfo;
		this.setState({labelTVMain:label, subArr});
		this.setModalSub(subArr[0].subKey);
		gtag("event", "tv_spec_view", { app:'instore', tv_name: modalSub });
	}

	setModalSub = (selSubKey) => {
		this.selSubInfo = this.selMainInfo.subArr.find(item=>item.subKey===selSubKey);
		this.setState({selSubKey, imgTVMain:this.selSubInfo.imgSrc});
		const {subLabel} = this.selSubInfo;
		if (subLabel) this.setState({labelTVMain:subLabel});
		this.setState({labelBackLight:selSubKey==='LSP3B'?'Big Screen:':'Backlight:'})
		this.setModalInfo();
		gtag("event", "tv_spec_sub_view", { app:'instore', tv_name: this.state.modalSub, sub_name: selSubKey });
	}

	setModalInfo = () => {
		const {labelPro, labelLight, labelHigh0, labelHDR, labelBenefit0} = subModalLabel, {modalSub} = this.state;
		var dis6Pro, dis2Year, dis3Year, dis5Year;
		[
			{divId:'subValPro', key:'pro', default:labelPro},
			{divId:'subValHDR', key:'hdr', default:labelHDR},
			{divId:'subValLight', key:'light', default:labelLight},
			{divId:'subValHigh0', key:'high0', default:labelHigh0},
			{divId:'subValHigh1', key:'high1', default:false},
			{divId:'subBenefit0', key:'benefit0', default:labelBenefit0},
			{divId:'subBenefit1', key:'benefit1', default:false},
			{divId:'labelInch', key:'labelInch', default:''},
			// {divId:'disclaimer', key:'disclaimer', default:''},
		].forEach(item => {
			const labelStr = this.selSubInfo[item.key] || this.selMainInfo.detail[item.key] || item.default;
			this.setState({[item.divId]:labelStr})
			if (!item.key.includes('benefit')) return;
			if 		(labelStr==='6pro') dis6Pro = true;
			else if (labelStr==='2year') dis2Year = true;
			else if (labelStr==='5year') dis5Year = true;
		});
		this.setState({dis2Year, dis3Year, dis5Year, dis6Pro, disLife:modalSub==='tvLife'});
	}

	onClickDisclaimer = (subBenefit1) => {
		if (!subBenefit1) return;
		var siteUrl;
		if (subBenefit1.includes('https://www.samsclub.com/content/sams-cash?xid=hdr_sams-cash')) siteUrl = 'https://www.samsclub.com/content/sams-cash?xid=hdr_sams-cash';
		else if (subBenefit1.includes('https://www.samsung.com/us/promotions/yourchoice')) siteUrl = 'https://www.samsung.com/us/promotions/yourchoice';
		if (siteUrl) this.openSite(siteUrl)
	}

	openSite = (siteUrl) => {
		gtag("event", "open_samsclub", { app:'instore', site_name:  siteUrl});
		const newWindow = window.open(); newWindow.location = siteUrl; // window.open(siteUrl);
	}

	addUrl = (urlStr, linkStr) => {
		return <label className='url' onClick={()=> {
			this.openSite(linkStr || urlStr);
		}}>{urlStr}</label>
	}

	getBenefitStr = (key) => {
		if		(key==='2year') return benefit2Year;
		else if (key==='3year') return benefit3Year;
		else if (key==='5year') return benefit5Year;
		else if (key==='6pro') return benefit6Pro;
		else return '';
	}

	render() {
		const {modalSub, modalSubIn, imgTVMain, selSubKey, labelTVMain, subArr, labelBackLight, subValPro, subValHDR, subValLight, subValHigh0, subValHigh1, subBenefit0, subBenefit1, disclaimer, labelInch, tSize, dis2Year, dis5Year, dis6Pro, disLife} = this.state;
		return (
			<div className={`modal-back ${modalSubIn?'active':''} modal-sub-${modalSub}`} id="modalSub" style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
				<div className="modal-wrapper">
					<div className="modal-side tv-side">
						<div className="main-image flex"><img src={imgTVMain} alt=''></img></div>
						<div className="member-part">
							<div className="member-title flex">
								<img src={imgStarWhite} alt=''></img>
								<div className="label">Sam's Club Member Benefits</div>
							</div>
							<div className="member-des flex">
								<div className="label flex">{this.getBenefitStr(subBenefit0)}</div>
								<div className={`label flex ${subBenefit1?'':'hide'}`}>{this.getBenefitStr(subBenefit1)}</div>
								<div className={`middle-line ${subBenefit1?'':'hide'}`}></div>
							</div>
						</div>
					</div>
					<div className="modal-side text-side">
						<div className="side-title-wrapper">
							<div className="title-row">
								<div className="title-left button" id='labelTVMain'>{labelTVMain}</div>
								<div className="title-right">
									<div className="side-title-pre">Series</div>
									<div className="sub-tv-name-wrapper">
										{subArr.map(item=>
											<div className={`sub-tv-name sub-tv-label ${selSubKey===item.subKey?'active':''}`} onClick={()=>this.setModalSub(item.subKey)} key={item.subKey}>{item.subKey}</div>
										)}
									</div>
								</div>
							</div>
							<div className="title-row inch-row">
								<div className="sub-tv-name active">{labelInch}</div>
							</div>
						</div>
						<div className="side-content scroll scroll-y">
							<div className="option-item">
								<div className="int-line"></div>
								<div className="label">Processor:</div>
								<div className="value">{subValPro}</div>
							</div>
							<div className="option-item">
								<div className="int-line"></div>
								<div className="label">Smart:</div>
								<div className="value">Smart TV Powered by Tizen</div>
							</div>
							<div className="option-item">
								<div className="int-line"></div>
								<div className="label">{labelBackLight}</div>
								<div className="value">{subValLight}</div>
							</div>
							<div className="option-item">
								<div className="int-line"></div>
								<div className="label">High Dynamic Range:</div>
								<div className="value">{subValHDR}</div>
							</div>
							<div className="option-item">
								<div className="int-line"></div>
								<div className="label">Highlight:</div>
								<div className="value">{subValHigh0}</div>
							</div>
							<div className={`option-item ${subValHigh1?'':'hide'}`}>
								<div className="int-line"></div>
								<div className="label">Highlight:</div>
								<div className="value">{subValHigh1}</div>
							</div>
							<div className={`option-item disclaimer`}>
								<div className="label">Disclaimer:</div>
								<div className="value">
									{dis2Year && <div>*2 Year art store credit for 55” above models  Valid 4/15/21-5/31/23. Must select annual plan for promotion to apply. Credit card require to redeem. Subscription auto-renews after promotional period unless cancelled.</div>}
									{dis6Pro && <div>*Offer good until 1/31/2023 to Plus Members only with in-club TV purchase of $997 and up (excluding taxes and shipping). 6% Sam’s Cash TM applies to the cost of the TV. Base Sam’s Cash TM will apply to all other qualifying items purchased at the same time as TV. See Sams Cash TM Program Terms and Conditions for details {this.addUrl('https://www.samsclub.com/content/sams-cash?xid=hdr_sams-cash')}</div>}
									{dis5Year && <div>**Offer valid with purchase of select television models from Sam’s Club while supplies last. For online orders, please select “add to cart” for Allstate Protection Plan, and the price for the Allstate Protection Plan will be removed at checkout. Eligible in-club television purchases must be completed with a Sam’s Club cashier or through self-checkout. Limitations and exclusions apply. See terms and conditions at {this.addUrl('AllstateProtectionPlans.com/samsclub', 'https://www.squaretrade.com/samsclub/')} for complete coverage details.</div>}
								</div>
							</div>
						</div>
						<div className="button member flex" id='btnShop' onClick={()=>{
							const tvKeyStr = this.selMainInfo.label==='Neo QLED 8K'?'8K':this.selMainInfo.label;
							const tvUrl = tvKeyStr.split(' ').join('%20'), siteUrl = preShopUrl+tvUrl;
							this.openSite(siteUrl);
						}}>Shop Now at SamsClub.com</div>
					</div>
					<div className="oblique oblique-top-left"></div>
					<div className="oblique oblique-bottom-right"></div>
				</div>
				<img className="close" src={imgClose} onClick={()=>this.props.closeModalSub()} alt=''></img>
			</div>
		);
	}
}
