import React from 'react';
import { tvModelArr } from '../data/info';
import imgClose from '../assets/images/close.png';
import imgModalList from '../assets/images/modal-list.jpg';
import imgInfoWhite from '../assets/images/info_white.png';

export default class ModalMainComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {modalMain: props.modalMain, modalMainIn:props.modalMainIn, tSize:props.tSize};
	}

	componentDidMount() {
	}

	componentWillReceiveProps(nextProps) {
		['modalMain', 'modalMainIn', 'tSize'].forEach(key => {
			if (this.state[key] !== nextProps[key]) {
				this.setState({[key]:nextProps[key]});
			}
		});
	}

	clickExitBtn = (key) => {
		this.props.closeMainModal();
		setTimeout(() => {
			if (key==='more') this.props.openMainModal('tvList');
			else this.props.setExitVideo();
		}, 600);
	}

	setPage = (nextPage) => {
		this.props.closeMainModal();
		setTimeout(() => {
			if 		(nextPage==='photo') this.props.openPhotoPage();
			else if (nextPage==='surround') this.props.openMainModal(nextPage);
			else this.props.setPageKey(nextPage);
		}, 600);
	}

	render() {
		const {modalMain, modalMainIn, tSize} = this.state;
		return (
			<div className={`modal-back ${modalMainIn?'active':''} modal-main-${modalMain}`} id="modalMain" style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
				<div className={`modal-wrapper ${modalMain==='exit'?'flex':''}`}>
					{modalMain==='vipList' &&
						<>
							<div className='modal-content scroll scroll-y'>
								<img src={imgModalList} alt=''></img>
							</div>
							<img className="close" src={imgClose} onClick={()=>this.props.closeMainModal()} alt=''></img>
						</>
					}
					{modalMain==='tvList' &&
						<>
							<div className="modal-title">What type of TV are you interested in?</div>
							<div className="modal-content">
								<div className="row flex" id="tvMainBtnList">
									{tvModelArr.map(item=>
										<div className='button tv-button' key={item.key} onClick={()=>this.props.setSelTV(item.key)}>{item.label}</div>
									)}
								</div>
								<div className="row flex">
									<div className="button tv-button" id="btnTVBrowse" onClick={()=>this.props.closeMainModal()}>Just Browsing</div>
								</div>
							</div>
						</>
					}
					{modalMain==='exit' &&
						<>
							<div className="button" onClick={()=>this.clickExitBtn('more')}>SEE MORE</div>
							<div className="button" onClick={()=>this.clickExitBtn('exit')}>EXIT CLUB</div>
						</>
					}
					{modalMain==='photo' &&
						<>
							<div className="modal-title">Would you like to take a photo with Kevin?</div>
							<div className="modal-content flex">
								<div className="button" onClick={()=>this.setPage('photo') }>LET’S DO IT</div>
								<div className="button" onClick={()=>this.setPage('thanks') }>NO THANKS</div>
							</div>
						</>
					}
					{modalMain==='chromeNote' &&
						<div className="modal-content flex">
							<img src={imgInfoWhite}></img>
							<div className="note-label">Please update your Google chrome browser to the latest version from settings.</div>
							<div className='note-label'>And disable <label>'Fullscreen Smooth Scrolling'</label>  option in <label>chrome://flags</label>.</div>
							<div className="note-label">
								<div className="button tv-button" onClick={()=> this.setPage('surround') }>GOT IT</div>
							</div>
						</div>
					}
					{modalMain==='surround' &&
						<div className="modal-content flex">
							<div className="note-label">Please be aware of</div>
							<div className='note-label'>your surroundings</div>
							<div className="button tv-button" onClick={()=> this.props.closeMainModal('surround') }>CONFIRM</div>
						</div>
					}
				</div>
			</div>
	
		);
	}
}
