import React, { Component } from 'react';
import MainComponent from './components/Main';
class App extends Component {
  render() {
    return (
      <MainComponent></MainComponent>
    );
  }
}

export default App;
