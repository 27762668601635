import React from 'react';
import imgPlay from '../assets/images/play.png';
import {videoArr} from '../data/info'; // videoUrl

export default class VideoComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {pageKey: props.pageKey, selVideo:props.selVideo, btnLabel:''};
	}

	componentDidMount() {
		window.addEventListener('focus', () => { this.setVideoStatus('focus') });
		window.addEventListener('blur', () => { this.setVideoStatus('blur') });
		videoArr.forEach(item => {
			const videoEle = document.getElementById(item.key);
			if (item.key==='intro' || item.key==='outro') videoEle.volume = 0.4; // else videoEle.volume = 1;
		});
		this.soundSME60 = document.getElementById('soundSME60');
		this.soundSME15 = document.getElementById('soundSME15');
	}
	
	componentWillReceiveProps(nextProps) {
		['pageKey', 'selVideo'].forEach(key => {
			if (this.state[key] !== nextProps[key]) {
				this.setState({[key]:nextProps[key]}, () => {
					if (key==='selVideo') this.setVideo();
				});
			}
		});
		if (!this.state.scanPass && nextProps.scanPass) {
			this.setState({scanPass:true});
		}
	}

	setVideoStatus = (status) => {
		const {selVideo} = this.state;
		if (!selVideo) return;
		const selVideoEle = document.getElementById(selVideo);
		if (status === 'focus' && !this.flagVideoEnd) selVideoEle.play();
		else if (status === 'blur') selVideoEle.pause();
	}

	setVideo = () => {
		this.selVideoEle = null;
		const {selVideo} = this.state, videoEleArr = document.getElementsByClassName('back-video');
		for (let i = 0; i < videoEleArr.length; i++) {
			const video = videoEleArr[i];
			video.currentTime = 0; 
			if (video.id===selVideo) {
				this.selVideoInfo = videoArr.find(item=>item.key===selVideo)
				this.flagVideoEnd = false;
				this.selVideoEle = document.getElementById(selVideo);
				this.setState({btnLabel:'SKIP'}, () => {
					// this.selVideoEle.currentTime = this.selVideoInfo.endTime - 5;
					this.selVideoEle.muted = false;
					this.selVideoEle.play();
					this.checkTimeVideo(selVideo);
				});
				gtag("event", "video_view", { app:'instore', video_name: selVideo });
				if (selVideo==='mStart') {
					setTimeout(() => { this.checkRotateLand(); }, 13000 );
				}
			}
			else { video.pause(); video.muted = true;}
		}
	}

	checkTimeVideo = (videoId) => {
		const {selVideo} = this.state;
		if (!selVideo || !this.selVideoEle || videoId !== selVideo) return;
		if (this.selVideoEle.currentTime > this.selVideoInfo.endTime && !this.flagVideoEnd) {
			this.flagVideoEnd = true;
			this.selVideoEle.currentTime = this.selVideoInfo.pauseTime || this.selVideoEle.currentTime;
			this.selVideoEle.pause();
			if (selVideo==='intro' || selVideo==='outro' || selVideo==='mEnd') {
				this.endVideo();
			} else {
				this.setState({btnLabel:this.selVideoInfo.class==='tv'?'BACK':'NEXT'})
			}
			if (videoId==='mStart') this.props.setEnableList(true);
		}
		else setTimeout(() => { this.checkTimeVideo(videoId) }, 500);
	}

	checkRotateLand = () => {
		if (this.state.selVideo!=='mStart') return;
		if (this.selVideoEle.currentTime > 14 && !this.flagRotate) {
			this.flagRotate = true; this.props.setRotateLand();
			setTimeout(() => { this.checkScanPass() }, 7000);
		} else setTimeout(() => { this.checkRotateLand(); }, 500);
	}

	checkScanPass = () => {
		if (this.state.selVideo!=='mStart') return;
		if (this.selVideoEle.currentTime > 22 && !this.state.scanPass) {
			this.props.setScanPass();
		} else setTimeout(() => { this.checkScanPass(); }, 500);
	}

	endVideo = () => {
		const {selVideo} = this.state;
		this.props.endVideo();
		if 		(selVideo==='mStart') {this.props.setPart('door'); this.props.setEnableList(true);}
		else if (selVideo==='intro') {this.props.openMainModal('tvList'); this.props.setShowMenu(); this.props.setPart('inClub');}
		else if (selVideo==='outro') this.props.setExitClub();
		else if (selVideo==='mEnd') this.props.openMainModal('photo');
	}

	render() {
		const {selVideo, btnLabel} = this.state;
		return (
			<>
				{videoArr.map(item=>
					<video className={`back-video`} id={item.key} playsInline crossOrigin="anonymous" src={item.src} key={item.key}></video> // onEnded={() => {this.endVideo()}}
				)}
				<div className={`button ${selVideo?'active':''} ${btnLabel.toLowerCase()}`} id="btnVideoSkip" onClick={()=>this.endVideo()}>{btnLabel}<img src={imgPlay} alt=''></img></div>
			</>
		);
	}
}
