
import modelTVUhd from '../assets/model/qn_900_n.glb';
import modelTVQled from '../assets/model/qn_80_n.glb';
import modelTVNeo from '../assets/model/qn_85_b.glb';
import modelTVLife from '../assets/model/ls_03_b.glb';
import modelTV8K from '../assets/model/qn_800_b.glb';

import imgEnvPX from '../assets/images/px.jpg';
import imgEnvPY from '../assets/images/py.jpg';
import imgEnvPZ from '../assets/images/pz.jpg';
import imgEnvNX from '../assets/images/nx.jpg';
import imgEnvNY from '../assets/images/ny.jpg';
import imgEnvNZ from '../assets/images/nz.jpg';

import imgTVUhd_AU8 from '../assets/tv_thumb/Uhd_AU8.jpg';
import imgTVQled_Q60 from '../assets/tv_thumb/Qled_Q60.jpg';
import imgTVQled_Q70 from '../assets/tv_thumb/Qled_Q70.jpg';
import imgTVQled_Q80 from '../assets/tv_thumb/Qled_Q80.jpg';
import imgTVNeo_QN85 from '../assets/tv_thumb/Neo_QN85.jpg';
import imgTVNeo_QN90 from '../assets/tv_thumb/Neo_QN90.jpg';
import imgTVNeo_QN95 from '../assets/tv_thumb/Neo_QN95.jpg';
import imgTVLife_LS03 from '../assets/tv_thumb/Life_Frame.jpg';
import imgTVLife_LST7 from '../assets/tv_thumb/Life_Terrace.jpg';
import imgTVLife_LSP3B from '../assets/tv_thumb/Life_Freestyle.jpg';
import imgTV8K_QN850 from '../assets/tv_thumb/8K_QN850.jpg';

import mStart from '../assets/videos_mp4/Main_start.mp4';
import mEnd from '../assets/videos_mp4/Main_end.mp4';
import intro from '../assets/videos_mp4/Intro.mp4';
import outro from '../assets/videos_mp4/Outro.mp4';
import tvUhd from '../assets/videos_mp4/CrystalUHD.mp4';
import tvQled from '../assets/videos_mp4/QLED.mp4';
import tvNeo from '../assets/videos_mp4/NeoQLED.mp4';
import tvLife from '../assets/videos_mp4/Lifestyle.mp4';
import tv8K from '../assets/videos_mp4/8K.mp4';

export const videoArr = [
	{key:'mStart', src:mStart, class:'out', endTime:75.5, pauseTime:48.91},
	{key:'intro', src:intro, class:'in', endTime:52.5},
	{key:'tvUhd', src:tvUhd, class:'tv', endTime:35},
	{key:'tvQled', src:tvQled, class:'tv', endTime:41},
	{key:'tvNeo', src:tvNeo, class:'tv', endTime:50.5},
	{key:'tvLife', src:tvLife, class:'tv', endTime:53},
	{key:'tv8K', src:tv8K, class:'tv', endTime:34.5},
	{key:'outro', src:outro, class:'in', endTime:25},
	{key:'mEnd', src:mEnd, class:'out', endTime:50, pauseTime:19.15},
]

export const videoUrl = 'https://ss-instore.rungra888.com/video/';
export const imgEnvArr = [imgEnvPX, imgEnvNX, imgEnvPY, imgEnvNY, imgEnvPZ, imgEnvNZ];
const labelPro = 'Quantum Processor with 4K Upscaling', labelProLite = 'Quantum Processor Lite with 4K Upscaling',
labelLight='Direct Lit (AU:w/Contrast Enhancer)', labelLightDual = 'Dual LED Backlight',
labelHigh0='Slim ”OneRemote” (AU w/Voice Remote)', labelLightDirect='Direct Full Array Backlight', 
labelHDR = 'HDR', labelHDRQ = 'Quantum HDR', labelHdr32 = 'Quantum HDR 32x',
labelHighMotion = 'Motion Xcelerator Turbo+';
export const benefit6Pro = 'Plus member get 6% Sam’s Cash back on Samsung TVs $997 and up*', benefit5Year = '5-year Allstate Protection Plan at no additional cost.**', benefit2Year = 'Up to 2 year Art Store Credit= $100 Value*', benefit3Year = '3-year Manufacturer’s Warranty';
export const subModalLabel = {labelPro, labelHDR, labelLight, labelHigh0, labelBenefit0 : '3year'};


const subUhdArr = [{subKey:'TU7+AU8', imgSrc:imgTVUhd_AU8, labelInch:'43”, 50”, 55”, 58”, 60”, 65”, 70”, 75”, 82”, 85”'}], detailUhd = {pro:'Crystal Processor with 4K Upscaling', light:labelLight, high1:'AU:w/ Object Tracking Sound Lite', benefit1:'6pro'};
const subQledArr = [
		{subKey:'Q60', imgSrc:imgTVQled_Q60, labelInch:'43”, 50”, 55”, 60”, 65”, 70”, 75”, 85”', pro:labelProLite, high0:'Solar Cell Rechargeable Voice Enabled Remote', high1:'Clear Picture Motion Xcelerator'},
		{subKey:'Q7D', imgSrc:imgTVQled_Q70, labelInch:'55”, 65”, 75”, 85”', high0:'120Hz display w/Wide Viewing Angle', high1:labelHighMotion},
		{subKey:'Q80', imgSrc:imgTVQled_Q80, labelInch:'50”, 55”, 65”, 75”, 85”', light:labelLightDirect, hdr:'Quantum HDR 12 x (50” 8x)', high0:'4 HDMI v2.1 & Wide Viewing Angle', high1:'Object Tracking Sound + (50” Lite)'} ],
	detailQled = {light:labelLightDual, hdr:labelHDRQ, benefit0:'5year', benefit1:'6pro'};
const subNeoArr = [
		{subKey:'QN85', imgSrc:imgTVNeo_QN85, labelInch:'55”, 65”, 75”, 85”', hdr:'Quantum HDR 24x', high0:'Samsung NeoSlim Bezeless TV design', high1:labelHighMotion},
		{subKey:'QN90', imgSrc:imgTVNeo_QN90, labelInch:'43”, 50”, 55”, 65”, 75”, 85”', hdr:'Quantum HDR 32x (43”/50” 24x)', high0:'Anti-Reflection Screen w/ Ultra Viewing Angle (>50”)', high1:'Object Tracking Sound + (43”/50” Lite)'},
		{subKey:'QN95', imgSrc:imgTVNeo_QN95, labelInch:'55”, 65”, 75”, 85”', pro:'Neural Quantum Processor with 4K AI Upscaling', hdr:labelHdr32, high0:'Next-Gen PC gaming capabilities up to 144Hz', high1:'Object Tracking Sound +'} ],
	detailNeo = {pro:'Neo Quantum Processor with 4K AI Upscaling', light:'Quantum Matrix w/Mini LEDs', benefit0:'5year', benefit1:'6pro'};
const subLifeArr = [
		{subKey:'LS03', subLabel:'The Frame', imgSrc:imgTVLife_LS03, labelInch:'32”, 43”, 50”, 55”, 65”, 75”, 85”', pro:'Quantum Processor with 4K Upscaling (32” FHD)', hdr:'Quantum HDR 4x(32” HDR)', high0:'Art Mode', high1:'Anti-Reflection Screen / Slim Mount Included (>43”)', benefit1:'2year'},
		{subKey:'LST7', subLabel:'The Terrace', imgSrc:imgTVLife_LST7, labelInch:'55”, 65”, 75”', light:labelLightDirect, hdr:labelHdr32, high0:'OUTDOOR Weather-resistant (IP55 Rated)', high1:'Adaptive Sound', benefit1:'6pro'},
		{subKey:'LSP3B', subLabel:'The Freestyle', imgSrc:imgTVLife_LSP3B, labelInch:'Projects 30”-100”', pro:'Full HD Processor HDR', light:'Project 30-100 Inch Size', hdr:'HDR', high0:'Premium 360 Sound Built in', high1:'Ultra portability & easy to use Auto Keystone'}],
	detailLife = {light:labelLightDual, hdr:labelHDRQ, benefit0:'5year'};
const sub8KArr = [ {subKey:'QN850', imgSrc:imgTV8K_QN850, labelInch:'65”, 75”, 85”'} ], detail8K = {benefit0:'5year', benefit1:'6pro', pro:'Neural Quantum Processor with 8K AI Upscaling', light:'Quantum Matrix Pro with Mini LEDs', hdr:'Quantum HDR 32x', high0:'Dolby Atmos and Object Tracking Sound Pro', high1:'Ultimate 8K Dimming Pro'}

export const tvModelArr = [
	{key:'tvUhd', 	file:modelTVUhd, rot:240, pos:[0.066, 0.01, 0.103],	 infoPos:[0.72, 0.87, 0.03], label:'Crystal UHD', subArr:subUhdArr, detail:detailUhd},
	{key:'tvQled', 	file:modelTVQled,rot:210, pos:[0.037, 0.012, 0.130], infoPos:[0.94, 1.15, 0.05], label:'QLED', subArr:subQledArr, detail:detailQled},
	{key:'tvNeo', 	file:modelTVNeo, rot:180, pos:[-0.001, 0.014, 0.137],infoPos:[0.93, 1.15, 0.02], label:'Neo QLED', subArr:subNeoArr, detail:detailNeo},
	{key:'tvLife', 	file:modelTVLife,rot:150, pos:[-0.038, 0.016, 0.124], infoPos:[0.94, 1.1, 0.04], label:'Lifestyle', subArr:subLifeArr, detail:detailLife},
	{key:'tv8K', 	file:modelTV8K, rot:120, pos:[-0.063, 0.017, 0.094], infoPos:[0.94, 1.15, 0.07], label:'Neo QLED 8K', subArr:sub8KArr, detail:detail8K},
]
