
import React from 'react';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import modelClub from '../assets/model/samsung_club_6_5.glb';

import imgInfo from '../assets/images/info_icon.png';
import imgLogo from '../assets/images/logo.png';
import imgShop from '../assets/images/menu_shop.png';
import imgTV from '../assets/images/menu_tv.png';
import img360Icon from '../assets/images/360_white.png';
import imgClose from '../assets/images/close.png';
import {tvModelArr, imgEnvArr, videoArr} from '../data/info';

const camFov = {land:54, port:65}
const modelH = 10, doorAniTime = 30, clubAniTime = 45, tvAniTime = 45, deltaDisDoor = 2/doorAniTime, deltaClub=-18/clubAniTime, delta360 = 1/tvAniTime;

export default class CanvasComponent extends React.Component {
	constructor(props) {
		super(props);
		const {pageKey, dir, tSize, enableList, modalMain} = props;
		this.deltaDisTV = {}; this.inDoorArr = []; this.infoArr = []; this.tvMeshArr = []; this.tvArr = []; this.videoMeshArr = [];
		this.loadedCount = 0; this.doorTime = -1; this.dirDoor = 0; this.cameraTime = 0; this.clubTime = -1; this.tvTime = -1; this.time360 = -1; this.photoStatus = false; this.flagIn = 1;
		this.raycaster = new THREE.Raycaster(); this.mouse = new THREE.Vector2();
		this.state = {pageKey, dir, tSize, enableList, modalMain, dragX:0};
	}

	componentDidMount() {
		if (this.props.device) {
			this.initScene();
			this.loadTest();
			this.loadVideoBack();
			this.animate();
			this.setCanvasSize();
			this.transDir = true;
		}
	}

	componentWillReceiveProps(nextProps) {
		['pageKey', 'dir', 'selTV', 'selVideo', 'menuBar', 'showMenu', 'partKey', 'tSize', 'enableList', 'modalMain'].forEach(key => {
			if (this.state[key] !== nextProps[key]) {
				this.setState({[key]:nextProps[key]}, () => {
					const {partKey, selTV, pageKey} = this.state;
					if 		(key==='selTV') {if (selTV) this.clickTV(selTV); else this.resetTV();}
					else if	(key==='pageKey' && pageKey==='loading' && this.props.device) {this.loadModel();}
					else if (key==='selVideo') this.setSelVideo();
					else if (key==='tSize') this.setCanvasSize();
					else if (key==='partKey') {
						if 		(partKey==='doorOpen') this.openDoor();
						else if (partKey==='exit') this.gotoOut();
						else if (partKey==='readyCanvas') this.requestPermission();
					}
				});
			}
		});
	}

	setCanvasSize = () => {
		const {tSize} = this.state;
		this.cWidth = tSize.w; this.cHeight = tSize.h; this.deltaLeft = tSize.l; this.dir = tSize.w>tSize.h?'land':'port';
		this.sAngle = null;
		if (!this.renderer || !this.camera) return;
		this.renderer.domElement.style.marginLeft = -this.deltaLeft+"px";
		this.renderer.setSize(this.cWidth, this.cHeight);
		this.camera.aspect = this.cWidth/this.cHeight;
		this.camera.fov = camFov[this.dir] // this.camera.updateProjectionMatrix();
	}

	onMouseDown = (e) => { // if (this.time360 > -1) return;
		if (!e.touches && !e.changedTouches) e.touches = [{pageX:e.pageX, pageY:e.pageY}];
		this.dragX = e.touches[0].pageX; this.deltaDragRotTV = 0; if (this.selTV) this.rotSelTV = this.selTV.rotation.y;
	}

	onMouseMove = (e) => {
		if (!this.selTV || this.tvTime > -1 || this.time360 > -1) return;
		if (!e.touches && !e.changedTouches) e.touches = [{pageX:e.pageX, pageY:e.pageY}];
		this.deltaDragRotTV = (e.touches[0].pageX - this.dragX) * 0.005;
		this.selTV.rotation.y = this.rotSelTV + (this.deltaDragRotTV || 0);
	}

	onClickCanvas = (e) => {
		const {selModalSub, enableList, partKey, modalMain} = this.state;
		if (enableList) {this.checkClickList(e); return;}
		if (partKey!=='inClub' || modalMain) return;
		if (!e.touches && !e.changedTouches) e.touches = [{pageX:e.pageX, pageY:e.pageY}];
		if (Math.abs(e.touches[0].pageX - this.dragX) > 5) return;
		const interInfo = this.getClickObj(e, this.infoArr);
		if (!selModalSub && interInfo) { this.props.setModalSub(interInfo.object.infoKey.substring(5)); return; }

		if (this.tvTime >= 0) return;
		const interTV = this.getClickObj(e, this.tvMeshArr);
		if (interTV) {
			if (!this.selTV) { this.props.setSelTV(interTV.object.tvKey);} // else if (this.selTV && this.selTV.tvKey===interTV.object.tvKey) this.props.setSelTV();
		}
		this.dragX = 0;
	}

	checkClickList = (e) => {
		const interList = this.getClickObj(e, [this.meshList]);
		if (interList) { this.props.openMainModal('vipList'); }
	}

	getClickObj = (e, arr) => {
		if (!e.touches && !e.changedTouches) e.touches = [{pageX:e.pageX, pageY:e.pageY}];
		const touch = e.touches[0] || e.changedTouches[0];
		if (!touch) return;
		const posX = touch.pageX+this.deltaLeft, posY = touch.pageY;
		this.mouse.x = ( posX / this.cWidth ) * 2 - 1;
		this.mouse.y = - ( posY / this.cHeight ) * 2 + 1;
		this.raycaster.setFromCamera( this.mouse, this.camera );
		const interObj = this.raycaster.intersectObjects( arr )[0];
		return interObj;
	}

	clickTV = (tvKey) => {
		const posTarget = {x:tvKey==='tvUhd'?0.023 :0.02, y:0.013, z: 0.06}; // 0.05
		this.clickFlagTV = true; this.rotSelTV = Math.PI; this.dirScaleTVIcon = 1;
		this.selTV = this.tvArr.find(tv=>{return tv.tvKey===tvKey}); this.selTVIcon = this.infoArr.find(info=>{return info.infoKey==='info_'+tvKey})
		this.deltaDisTV = {
			x:(posTarget.x - this.selTV.position.x)/tvAniTime,
			y:(posTarget.y - this.selTV.position.y)/tvAniTime,
			z:(posTarget.z - this.selTV.position.z)/tvAniTime,
		}
		this.deltaRotTV =  (Math.PI - this.selTV.rotation.y)/tvAniTime;
		this.selTvId = tvKey; this.tvDir = 1;
		this.tvTime = tvAniTime;
		this.props.setShowMenu(false);
	}
	
	resetTV = () => {
		this.props.setVideo(null);
		if (!this.selTV) return;
		this.selTV.rotation.y = Math.PI;
		this.selTVIcon.scale.set(1, 1, 1);
		this.selTVIcon.visible = false;
		// this.selTVIcon = null;
		this.tvDir = -1; this.tvTime = tvAniTime;
	}

	loadTest = () => {
		this.testGroup = new THREE.Group(); this.totalGroup.add(this.testMesh);
		const testGeo = new THREE.BoxGeometry(0.1, 0.1, 0.1);
		const testMat = new THREE.MeshStandardMaterial({color:0xFF0000});
		this.testMesh = new THREE.Mesh(testGeo, testMat); this.testMesh.position.z = -0.1;
		this.testGroup.add(this.testMesh);
	}

	setCamPos = (dX, dY, dZ) => {
		dX *= Math.PI/180; dY *= Math.PI/180; dZ *= Math.PI/180;
		this.testGroup.rotation.set(dX, dY, dZ);
		const wPos = this.testMesh.getWorldPosition(new THREE.Vector3());
		this.camera.position.set(wPos.x, wPos.y, wPos.z);
	}

	initScene = () => {
		this.renderer = new THREE.WebGLRenderer({antialias:true});
		this.renderer.outputEncoding = THREE.sRGBEncoding; // this.renderer.gammaOutput = true;
		if (!document.getElementById("container")) return false;
		document.getElementById("container").appendChild(this.renderer.domElement);

		this.renderer.setPixelRatio( window.devicePixelRatio || 1 );
		this.renderer.setClearColor(0x0F146B, 1);

		this.scene = new THREE.Scene();
		this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.01, 100);
		this.camera.position.set(0, 0, -0.1);
		this.setCanvasSize();

		this.totalGroup = new THREE.Group(); this.scene.add(this.totalGroup); this.totalGroup.position.y = modelH/-5;
		this.controls = new OrbitControls(this.camera, this.renderer.domElement); this.controls.enabled = false;
		// this.controls.enableDamping = true; this.controls.enablePan = false;
		
		this.ambientLight = new THREE.AmbientLight(0xFFFFFF, 1); this.scene.add(this.ambientLight);
		document.getElementById('container').addEventListener('touchstart', e=> { this.onMouseDown(e); });
		document.getElementById('container').addEventListener('touchmove', e=> { this.onMouseMove(e); });
		document.getElementById('container').addEventListener('pointerup', e=> { this.onClickCanvas(e); });
		// document.getElementById('container').addEventListener('touchend', e=> {this.onClickCanvas(e)}); // touchend
	}
	requestPermission = () => {
		if (!window.location.protocol.includes('https') ) {this.props.setLoadingPage(); return;}
		if (typeof DeviceMotionEvent.requestPermission === 'function') {
			DeviceMotionEvent.requestPermission().then((state) => {
				if (state === 'granted') {
					window.addEventListener('deviceorientation', this.handleOrientation);
				} else {
					window.alert('Request to access the orientation was rejected');
				}
				this.props.setLoadingPage();
			}).catch((err)=>{window.alert(err); this.props.setLoadingPage();});
		} else { window.addEventListener('deviceorientation', this.handleOrientation); this.props.setLoadingPage();}
	}
	getAngle = (cVal, sVal) => {
		var value = cVal - sVal;
		if (value>360) value-=360; else if (value<-360) value+=360;
		return value;
	}
	handleOrientation = (event) => {
		if (this.transDir) return;
		var {alpha, beta, gamma} = event, {dir} = this.state; // gamma *=2;
		if (dir==='port') {if (beta>0) beta+=180; else beta = 180 + beta;}
		if (!this.sAngle) {this.sAngle = {alpha, beta, gamma}; this.bAngle = {a:alpha, b:beta, g:gamma}; return;}

		const dA = Math.abs(alpha - this.bAngle.a), dG = Math.abs(gamma - this.bAngle.g), dB = Math.abs(beta - this.bAngle.b);

		var skipA = false;
		if (dA > 20) {this.sAngle.alpha = alpha - (this.bAngle.a - this.sAngle.alpha); skipA = true;}
		if (dG > 20) {this.sAngle.gamma = gamma - (this.bAngle.g - this.sAngle.gamma); skipA = true;}
		if (dB > 20) {this.sAngle.beta = beta - (this.bAngle.b - this.sAngle.beta); skipA = true;}
		this.bAngle = {a:alpha, b:beta, g:gamma};
		if (skipA) return;

		const dAlpha = this.getAngle(alpha, this.sAngle.alpha);
		const dBeta = this.getAngle(beta, this.sAngle.beta);
		const dGamma = this.getAngle(gamma, this.sAngle.gamma);
		this.setState({dGamma});
		if (dir==='land') this.setCamPos( dGamma, dAlpha, 0); // land
		else { this.setCamPos( dBeta*-0.6, (dAlpha+dGamma)/2, 0); }
	}

	loadModel = () => {
		const envMap = new THREE.CubeTextureLoader().load( imgEnvArr );
		new GLTFLoader().load( modelClub, (gltf) => {
			this.loadedCount++; this.props.setLoading(Math.floor(this.loadedCount/6*100))
			var object = gltf.scene;
			object.traverse(child => {
				if (child instanceof THREE.Mesh) {
					child.material.metalness = 0; child.material.roughness = 1; child.material.emissive.setHex(0x000000);
					if (child.name==='glass') child.material.transparent = true;
					else if (['floor_new'].includes(child.name)) { // 'wall_new1', 'floor_back', 
						child.material.envMap = envMap;
						child.material.roughness = 0.3;
						child.material.metalness = 0.1;
					}
				}
				if 		(child.name==="door2") {this.doorLeft = child;}
				else if (child.name==="door3") {this.doorRight = child;}
				else if (child.name.includes('door_inner')) {child.visible = false; this.inDoorArr.push(child);}
				else if (child.name==='list_display') this.meshList = child;
			})
			const vPos = new THREE.Box3().setFromObject(object), vSize = vPos.getSize(new THREE.Vector3()), scl = modelH/vSize.y;
			object.scale.set(scl, scl, scl); object.position.set(-0.1, 0, 16);
			this.modelClub = object;
			this.totalGroup.add(object);
			this.loadTVModel(envMap);
		}, (xhr) => { }, (error) => { console.log(error); } );
	}

	loadTVModel = (envMap) => {
		const infoMap = new THREE.TextureLoader().load(imgInfo);
		tvModelArr.forEach((item, idx) => {
			new GLTFLoader().load( item.file, (object) => { // FBXLoader modelClub
				object = object.scene;
				object.traverse(child => {
					if (child instanceof THREE.Mesh) {
						child.material.metalness = 0;
						child.material.roughness = 1;
						child.tvKey = item.key;
						this.tvMeshArr.push(child);
						if (child.name==='display') {
							child.material.envMap = envMap;
							child.material.roughness = 0.1;
							child.material.metalness = 0.1;
						}
						else if (child.name.includes('display_back')) child.material.side = 0;
					}
				})
				const scl = idx===0?0.022:0.018, infoScl = idx===0?0.136 * 1.3:0.16 * 1.3;
				object.scale.set(scl, scl, scl); object.position.set(item.pos[0], item.pos[1], item.pos[2]); object.rotation.y = (240-idx*30)/180*Math.PI;

				const infoGeo = new THREE.PlaneGeometry(infoScl, infoScl), infoMat = new THREE.MeshBasicMaterial({transparent:true, map:infoMap});
				const infoMesh = new THREE.Mesh(infoGeo, infoMat); infoMesh.infoKey = 'info_'+item.key; this.infoArr.push(infoMesh);
				infoMesh.position.set(item.infoPos[0], item.infoPos[1], item.infoPos[2]); infoMesh.visible = false;
				object.add(infoMesh); object.tvKey = item.key; this.tvArr.push(object);
				this.modelClub.add(object);
				this.loadedCount++; this.props.setLoading(Math.floor(this.loadedCount/6*100));
				if (this.loadedCount === 6) { this.props.setLoading(null);}
			}, (xhr) => { }, (error) => { console.log(error); } );
		});
		const rotateMap = new THREE.TextureLoader().load(img360Icon);
		const rotateGeo = new THREE.PlaneGeometry(0.02, 0.011);
		const rotateMat = new THREE.MeshBasicMaterial({map:rotateMap, side:2, transparent:true, opacity:0});
		this.mesh360 = new THREE.Mesh(rotateGeo, rotateMat); this.mesh360.position.set(0.021, 0.024, 0.058);  this.mesh360.rotation.y = Math.PI; this.mesh360.visible = false;
		this.modelClub.add(this.mesh360);
	}

	openDoor = () => {
		this.dirDoor = 1; this.doorTime = doorAniTime;
		gtag("event", "screen_view", { app:'instore', screen_name: 'club_inside' });
	}
	gotoOut = () => {
		this.flagIn = -1; this.dirDoor = 1; this.doorTime = doorAniTime; this.transDir = true;
		this.inDoorArr.forEach(child => { child.visible = false; });
		const {x, y, z} = this.camera.position;
		this.deltaCamera = {x:-x/doorAniTime, y:-y/doorAniTime, z:(-0.1-z)/doorAniTime};
		this.cameraTime = doorAniTime;
	}

	setSelVideo = () => {
		const {selVideo} = this.state;
		this.videoMeshArr.forEach(videoMesh => {
			videoMesh.visible = videoMesh.videoKey === selVideo;
		});
		if (!selVideo) this.resetTV();
	}

	loadVideoBack = () => {
		videoArr.forEach(item => {
			const videoEle = document.getElementById(item.key);
			const vertexShader = document.getElementById("vertexShader").textContent;
			const fragmentShader = document.getElementById("fragmentShader").textContent;
			const VideoMap = new THREE.VideoTexture(videoEle);
			VideoMap.minFilter = THREE.LinearFilter;
			VideoMap.magFilter = THREE.LinearFilter;
			const VideoGeo = new THREE.PlaneGeometry(modelH/3 * 0.55, modelH/3);
			const VideoMat = new THREE.ShaderMaterial({
				transparent: true,
				side: 2,
				uniforms: {
					map: { value: VideoMap },
					keyColor: { value: [0, 1, 0] },
					similarity: { value: 0.74 },
					smoothness: { value: 0 }
				},
				vertexShader: vertexShader,
				fragmentShader: fragmentShader
			});
			const videoMesh = new THREE.Mesh(VideoGeo, VideoMat);
			videoMesh.rotation.set(0, item.class==='tv'?Math.PI - 0.5:Math.PI, 0);
			videoMesh.position.set(item.class==='tv'?-2:0, item.class==='out'?modelH/3/2:modelH/3/2+0.1, item.class==='out'?2.5:3.5);
			videoMesh.videoKey = item.key; videoMesh.visible = false;
			this.totalGroup.add(videoMesh);
			this.videoMeshArr.push(videoMesh);
		});
	}

	show360Icon = (dir) => {
		this.dir360 = dir; 
		if (dir === 1) {
			this.mesh360.material.opacity = 0;
			this.mesh360.visible = true;
			this.time360 = 0;
			setTimeout(() => { this.show360Icon(-1) }, 2000);
		} else {
			this.mesh360.material.opacity = 1;
		}
	}

	animate=()=>{
		if (!this.camera || !this.scene) return;
		requestAnimationFrame(this.animate);
		this.rendering();
	}
	rendering = () => {
		if (!this.camera || !this.renderer) return;
		this.camera.lookAt( 0, 0, 0 );
		this.controls.update();
		this.renderer.render(this.scene, this.camera);
		this.camera.updateProjectionMatrix();
		
		if (this.time360 > -1) {
			if (this.time360 > tvAniTime && this.dir360 === 1) return;
			this.mesh360.material.opacity += delta360 * this.dir360; this.time360 += this.dir360;
			if (this.dir360 === -1 && this.time360 === -1) this.mesh360.visible = false;
		}
		if (this.selTVIcon && this.selTVIcon.visible) {
			const scl = this.selTVIcon.scale.x, nextScl = scl + this.dirScaleTVIcon * 0.01;
			this.selTVIcon.scale.set(nextScl, nextScl, nextScl);
			if 		(nextScl >= 1.6) this.dirScaleTVIcon = -1;
			else if (nextScl <= 1  ) this.dirScaleTVIcon = 1;
		}
		if (this.tvTime >= 0) {
			this.tvTime--
			this.selTV.position.x += (this.deltaDisTV.x * this.tvDir);
			this.selTV.position.y += (this.deltaDisTV.y * this.tvDir);
			this.selTV.position.z += (this.deltaDisTV.z * this.tvDir);
			this.selTV.rotation.y += (this.deltaRotTV * this.tvDir);
			if (this.tvTime < 0) {
				if (this.tvDir === 1) {
					this.selTVIcon.visible = true;
					this.props.setVideo(this.selTvId);
					gtag("event", "tv_click", { app:'instore', tv_name: this.selTvId });
					this.show360Icon(1);
				}
				else {this.selTvId = null; this.selTV = null; this.props.setShowMenu(true);}
				this.clickFlagTV = false;
			} // ['x', 'y', 'z'].forEach(axis => { this.selTV.position[axis] += this.deltaDisTV[axis] });
		}
		if (this.clubTime > 0) {
			this.clubTime--;
			this.modelClub.position.z += deltaClub * this.flagIn;
			if (this.clubTime===0) {
				this.dirDoor = -1;
				this.doorTime = doorAniTime;
			}
		}
		if (this.dirDoor !== 0 && this.doorTime >= 0) {
			this.doorLeft.position.x += deltaDisDoor * this.dirDoor;
			this.doorRight.position.x -= deltaDisDoor * this.dirDoor;
			this.doorTime--
			if (this.doorTime < 0) {
				if (this.dirDoor === 1) {
					this.clubTime = clubAniTime;
				} else if (this.dirDoor === -1) {
					if (this.flagIn === -1) {
						this.props.setVideo('mEnd');
					} else {
						this.inDoorArr.forEach(child => { child.visible = true; });
						this.transDir = false;
						this.props.setVideo('intro');
					}
				}
				this.dirDoor = 0;
			}
		}
		if (this.cameraTime > 0) {
			['x', 'y', 'z'].forEach(axis => {
				this.camera.position[axis] += this.deltaCamera[axis];
			});
			this.cameraTime--;
		}
	}

	clickMenuItem = (menuKey) => {
		this.props.setMenuBar(false);
		const shopUrl = 'https://www.samsclub.com/b/samsung-tvs-and-home-theater/11960109';
		if 		(menuKey==='shop') {
			gtag("event", "open_samsclub", { app:'instore', site_name: shopUrl });
			const newWindow = window.open(); newWindow.location = shopUrl; // window.open(showUrl);
		} else if (menuKey==='more') this.props.openMainModal('tvList');
	}

	clickNext = () => {
		this.resetTV();
		this.props.setMenuBar(false);
		this.props.openMainModal('exit');
	}

	render() {
		const {pageKey, tSize, partKey, showMenu, menuBar, dragX} = this.state;
		return (
			<div className={`back-board canvas ${pageKey==='canvas'?'active':''}`} style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
				<div id='container'></div>
				{/* <div className={`sub-label ${partKey==='door'?'active':''}`} id="subLabelDoor">Click V.I.P List table to show detail.</div>   */}
				<div className={`button ${partKey==='door'?'active':''}`} id="btnOpenDoor" onClick={()=>{
					this.props.setPartkey('doorOpen');
					this.props.setEnableList(false);
				}}>ENTER V.I.P. CLUB</div>
				<div className={`menu-icon flex ${showMenu?'show':''}`} onClick={()=>this.props.setMenuBar(true)}>
					<div></div><div></div><div></div>
				</div>
				<div className={`bottom-trans flex ${menuBar?'open':''}`} id="menuBar">
					<div className="menu-wrapper">
						<div className='close-bar'><img className="close" src={imgClose} alt='' onClick={()=>this.props.setMenuBar(false)}></img></div>
						{[
							{key:'logo', img:imgLogo, label:'', class:'full'},
							{key:'shop', img:imgShop, label:'Shop'},
							{key:'more', img:imgTV, label:'Learn More'}
						].map(item=>
							<div className="menu-item" onClick={()=> this.clickMenuItem(item.key) } key={item.key}><img src={item.img} className={item.class} alt=''></img><label>{item.label}</label></div>
						)}
					</div>
				</div>
				<div></div>
				<div className={`bottom-trans button ${showMenu?'open':''}`} id="btnTVNext" onClick={()=>this.clickNext()}>See More / Exit Club</div>{/* <img src={imgNextArrow} alt=''></img> */}
				<div className={`button button-center ${showMenu?'show':''}`} onClick={()=>this.sAngle=null}>RECENTER</div>
				<div className='setting flex'>{dragX}</div>
			</div>
		);
	}
}
