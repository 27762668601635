import React from 'react';
// import jQuery from 'jquery';
import StartComponent from './Start';
import CanvasComponent from './Canvas';
import LoadingComponent from './Loading';
// import PhotoComponent from './Photo';
import VideoComponent from './Video';
import ModalMainComponent from './ModalMain';
import ModalSubComponent from './ModalSub';

import mp3Out from '../assets/sound/Outside_160_75.mp3';
import mp3SME60 from '../assets/sound/SME_new_60.mp3';
import mp3SME15 from '../assets/sound/SME_new_15.mp3';

import imgScanBar from '../assets/images/scan-bar.png';
import imgRotate from '../assets/images/rotate.png';
import imgLogo from '../assets/images/logo.png';

import '../assets/css/index.css';

const {availWidth, availHeight} = screen, screenWidth = Math.max(availWidth, availHeight), screenHeight = Math.min(availWidth, availHeight);
const device = getDevice(), chromeFlag = (navigator.userAgent.match(/chrome|chromium|crios/i)), testMode = false; // tSize selVideo
function getDevice() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (/windows phone/i.test(userAgent)) { return "windows"; }
	if (/android/i.test(userAgent)) { return "android"; }
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { return "ios"; }
	return undefined;
}

export default class MainComponent extends React.Component {
	constructor(props) {
		super(props);
		const {innerWidth, innerHeight} = window, dir = innerWidth>innerHeight?'land':'port';
		this.state = {loadPro:0, pageKey:'', tSize:{w:innerWidth, h:innerHeight, l:0}, dir};
	}

	componentDidMount() {
		this.setCanvasSize();
		this.soundOut = document.getElementById('soundOut');
		this.soundSME60 = document.getElementById('soundSME60');
		this.soundSME15 = document.getElementById('soundSME15');
		window.addEventListener('orientationchange', ()=> {
			for (let i = 0; i < 7; i++) {
				setTimeout(() => { this.setCanvasSize(); }, 200 * i);
			}
		});
		window.addEventListener('resize', ()=> { this.setCanvasSize(); });
		window.addEventListener('blur', (event) => { this.soundOut.pause(); this.soundSME60.pause(); this.soundSME15.pause(); });
		window.addEventListener('focus', (event) => { this.setSound(); });

		const navigatorInfo = window.navigator
		const screenInfo = window.screen
		let uid = navigatorInfo.mimeTypes.length
		uid += navigatorInfo.userAgent.replace(/\D+/g, '')
		uid += navigatorInfo.plugins.length
		uid += screenInfo.height || ''
		uid += screenInfo.width || ''
		uid += screenInfo.pixelDepth || '';
		gtag("event", "user_id", { app:'instore', device_id: uid });
		gtag("event", "screen_view", { app:'instore', screen_name: 'start' });
		setTimeout(() => {
			if (device) {} else return; // this.setState({pageKey:'loading'});
			if (chromeFlag && device==='ios') this.openMainModal('chromeNote');
			else this.openMainModal('surround');
		}, 0);
	}

	setCanvasSize = () => {
		const {innerWidth, innerHeight} = window, dir = innerWidth>innerHeight?'land':'port', {appRotate} = this.state;
		const pageWidth = dir==='land'?screenWidth:screenHeight, pageHeight = dir==='port'?screenWidth:screenHeight;
		if 		(appRotate === 'noLand') { this.setState({showRotate:dir === 'land'}) }
		else if (appRotate === 'noPort') { this.setState({showRotate:dir === 'port'}) }
		const w = device==='ios'?pageWidth:innerWidth;
		this.setState({dir, tSize:{w, h:innerHeight, l:(innerWidth-w)/2}}, () => {
			const {partKey, dir} = this.state;
			if (partKey==='readyPhoto' && dir==='port') this.openPhotoSite(); // this.setState({pageKey:'photo'});
		});
	}

	openMainModal = (modalMain) => { this.setState({modalMain, selTV:undefined}, ()=>{ this.setState({modalMainIn:true}) }) }
	setSelModalSub = (modalSub) => { this.setState({modalSub, menuBar:false}, ()=>{ this.setState({modalSubIn:true}) }) }

	closeMainModal = (oldKey) => {
		this.setState({modalMainIn:null}, ()=> {
			if (oldKey==='surround') {this.setState({partKey:'readyCanvas'});}
			setTimeout(() => {
				this.setState({modalMain:null}) // if (oldKey==='surround' && this.state.loadPro===null) {this.setState({pageKey:'select', partKey:'readyCanvas'});}
			}, 500); 
		})
	}
	closeModalSub = () => { this.setState({modalSubIn:null}, ()=> {setTimeout(() => { this.setState({modalSub:null}) }, 500); }) }

	setCanvasPage = () => {
		this.setState({pageKey: 'canvas'});
		setTimeout(() => { this.setState({selVideo:'mStart'}, ()=> this.setSound()); }, 1000);
		gtag("event", "screen_view", { app:'instore', screen_name: 'club_door' });
	}

	setSound = () => {
		const {selVideo} = this.state;
		this.soundOut.pause(); // this.soundSME.pause();
		if (!selVideo) {
			if 		(this.clubSide==='outside')  this.soundOut.play();
			else if (this.clubSide==='inside') {
				this.soundSME60.muted = false; this.soundSME60.play();
				this.soundSME15.muted = true; this.soundSME15.play();
			}
		} else {
			if (!selVideo || selVideo ==='mStart' || selVideo==='mEnd') return;
			this.soundSME60.muted = true; this.soundSME60.play();
			this.soundSME15.muted = false; this.soundSME15.play();
		}
	}

	openPhotoSite = () => {
		gtag("event", "screen_view", { app:'instore', screen_name: 'photo_site' });
		window.open('https://photobooth.samsungsamsclubvip.com/camera', '_self');
	}

	setRotateLand = () => {
		this.setState({appRotate:'noPort'}, ()=> {
			if (this.state.dir==='port') this.setState({showRotate:true});
		} )
	}

	setInsideClub = () => {
		this.clubSide = 'inside'; this.setSound();
		this.setRotateLand();
	}

	setExitClub = () => {
		this.clubSide = 'exit'; this.soundSME60.pause();  this.soundSME15.pause();
		this.setState({appRotate:''});
		gtag("event", "screen_view", { app:'instore', screen_name: 'club_exit' });
	}

	render() {
		const {pageKey, loadPro, tSize, dir, menuBar, selVideo, selTV, modalMain, modalMainIn, modalSub, modalSubIn, showMenu, scanPass, partKey, appRotate, showRotate, enableList} = this.state;
		return (
			<div className={`page-wrapper ${device?'mobile':'web'} ${device} ${pageKey}-page `} style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
				<div className={`test-value ${testMode?'active':''}`} >{pageKey}</div>
				<audio id='soundOut' src={mp3Out} playsInline crossOrigin="anonymous" ></audio>
				<audio id='soundSME60' src={mp3SME60} playsInline crossOrigin="anonymous" loop></audio>
				<audio id='soundSME15' src={mp3SME15} playsInline crossOrigin="anonymous" loop></audio>
				<StartComponent
					dir={dir}
					tSize={tSize}
					device={device}
					pageKey={pageKey}
					callCanvasPage={()=> {
						this.clubSide = 'outside'; // this.setSound();
						this.setCanvasPage(); // this.setState({partKey:'readyCanvas'}, ()=> { this.setCanvasPage(); } )
					}}
				></StartComponent>
				<CanvasComponent
					tSize={tSize}
					pageKey={pageKey}
					device={device}
					partKey={partKey}
					showMenu={showMenu}
					selTV={selTV}
					selVideo={selVideo}
					dir={dir}
					menuBar={menuBar}
					enableList={enableList}
					modalMain={modalMain}
					setMenuBar={menuBar=>this.setState({menuBar})}
					setVideo={selVideo=>this.setState({selVideo}, ()=> { this.setSound(); if (selVideo==='mEnd') { this.setExitClub(); } } )} 
					setSelTV={(selTV)=>this.setState({selTV})}
					setModalSub={(modalSub)=>this.setSelModalSub(modalSub) }
					setLoading={(loadPro)=>{if (loadPro===null) this.setState({pageKey:'select'}); else this.setState({loadPro})}} // && !modalMain
					openMainModal={(mainModal)=>this.openMainModal(mainModal)}
					closeMainModal={this.closeMainModal}
					setLogoCustom={(logoCustom)=>this.setState({logoCustom})}
					setPartkey={(partKey)=>this.setState({partKey})}
					setEnableList={(enableList)=>this.setState({enableList})}
					setLoadingPage={()=>this.setState({pageKey:'loading'})}
					setShowMenu={(showMenu)=>this.setState({showMenu})}
				></CanvasComponent>
				<div className={`flex ${scanPass?'active scan':''}`} id="partScan">
					<img src={imgScanBar} alt=''></img>
					<label>SCANNING...</label>
				</div>
				<VideoComponent
					selVideo={selVideo}
					scanPass={scanPass}
					setPart={(partKey)=>this.setState({partKey}, ()=> {if (partKey==='door') { this.setInsideClub(); } } )}
					setRotateLand={()=>{this.setRotateLand();}}
					setScanPass={()=>this.setState({scanPass:true}, () => { setTimeout(() => { this.setState({scanPass:false}) }, 3900);})}
					openMainModal={(mainModal)=>{this.openMainModal(mainModal); }}
					setShowMenu={()=>this.setState({showMenu:true})}
					endVideo={()=>this.setState({selVideo:null, selTV:null}, ()=> this.setSound() )}
					setExitClub={()=>this.setState({partKey:'exit'})}
					setEnableList={(enableList)=>this.setState({enableList})}
				></VideoComponent>
				<ModalMainComponent
					tSize={tSize}
					modalMain={modalMain}
					modalMainIn={modalMainIn}
					setSelTV={(selTV)=>{this.closeMainModal(); setTimeout(() => { this.setState({selTV}) }, 500); }}
					openMainModal={(mainModal)=>this.openMainModal(mainModal)}
					closeMainModal={this.closeMainModal}
					setExitVideo={()=>this.setState({selVideo:'outro', showMenu:false, partKey:'endClub'}, ()=> this.setSound() )}
					setPageKey={(pageKey)=>this.setState({pageKey})}
					openPhotoPage={() => {
						this.setState({appRotate:'noLand', partKey:'readyPhoto'}, ()=> {
							if (dir==='port') this.openPhotoSite(); // this.setState({pageKey:'photo'});
							else this.setState({showRotate:true});
						})
					}}
				></ModalMainComponent>
				<ModalSubComponent
					tSize={tSize}
					modalSub={modalSub}
					modalSubIn={modalSubIn}
					closeModalSub={()=>this.closeModalSub()}
				></ModalSubComponent>
				{/* <PhotoComponent
					pageKey={pageKey}
					dir={dir}
					setEndPage={()=>this.setState({pageKey:'thanks'})}
				></PhotoComponent> */}
				<div className={`back-board blue-back part5-thanks flex ${pageKey==='thanks'?'active':''}`}>
					<img className="logo" src={imgLogo} alt=''></img>
					<label>Thank You!</label>
				</div>
				<LoadingComponent
					pageKey={pageKey}
					loadPro={loadPro}
					device={device}
					tSize={tSize}
				></LoadingComponent>
				<div className={`back-board part-rotate ${appRotate} ${showRotate?'active':''} flex`} style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
					<div className="black-wrapper flex">
						<img src={imgRotate} alt=''></img>
						{appRotate==='noLand' &&
							<div className="flex">
								<label className="label-rot-port">Please rotate your device to</label>
								<label className="label-rot-port">portrait orientation.</label>
							</div>
						}
						{appRotate==='noPort' &&
							<div className="flex">
								<label className="label-rot-land">Please rotate</label>
								<label className="label-rot-land">your device to</label>
								<label className="label-rot-land">landscape orientation.</label>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}
