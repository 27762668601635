import React from 'react';
import imgLogo from '../assets/images/logo.png';
import imgQR from '../assets/images/qr.jpg';

export default class LoadingComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {loadPro:0, pageKey:props.pageKey, tSize:props.tSize };
	}

	componentDidMount() {
	}

	componentWillReceiveProps(nextProps) {
		['pageKey', 'loadPro', 'tSize'].forEach(key => {
			if (this.state[key] !== nextProps[key]) {
				this.setState({[key]:nextProps[key]});
			}
		});
	}

	render() {
		const {pageKey, tSize} = this.state;
		return (
			<div className={`back-board flex blue-back loading ${pageKey==='loading'?'active':''} ${this.props.device?'':'active'}`} style={{width:tSize.w, height:tSize.h, left:tSize.l}}>
				<div className={`${this.props.device?'':'black-wrapper'} flex`}>
					<img className='logo' src={imgLogo} alt=''></img>
					{this.props.device ?
						<div className='loading-circle grey'></div>:
						<div className='desktop-word flex'>
							<div>Go to <label className='link'> https://samsungsamsclubvip.com</label></div>
							<div>or simply scan the below code with your</div>
							<div><label className='link'> mobile device </label> to access the experience!</div>
							<img src={imgQR}></img>
						</div>
					}
				</div>
			</div>
		);
	}
}
